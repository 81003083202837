import React from "react";
import { FaPhone } from "react-icons/fa6";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <div>
      <footer id="footer">
        <div className="container">
          <h3>The German Bakes</h3>

          <div className="row">
            <div className="col-lg-4">
              <div className="container link" style={{ textAlign: "left" }}>
                <strong style={{ color: "#ffb03b" }}>Information</strong>
                <ul
                  style={{
                    listStyleType: "none",
                    textAlign: "left",
                    paddingLeft: "0rem",
                  }}
                >
                  <Link to="/">
                    <li>
                      <i className="bx bx-check-double"></i> Home{" "}
                    </li>
                  </Link>
                  <Link to="/Contact">
                    <li>
                      <i className="bx bx-check-double"></i> Contact
                    </li>
                  </Link>
                  <Link to="/About">
                    <li>
                      <i className="bx bx-check-double"></i> About
                    </li>
                  </Link>
                  {/* <Link to="/Gallery">
                    <li>
                      <i className="bx bx-check-double"></i> Gallery
                    </li>
                  </Link> */}
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="container">
                <FaLocationArrow />
                <strong style={{ color: "#ffb03b" }}> Address</strong>
                <p>
                  Unit of Dinetech foods pvt Ltd, 57-58, Rajas Garden, Vanagaram
                  Main Road, Vanagaram, Porur, Chennai, Tamil Nadu-600116
                </p>
                <FaPhone />{" "}
                <strong style={{ color: "#ffb03b" }}> Contact</strong>
                <p>
                  <Link to="tel:+919940617739">
                    <span style={{ color: "#ffb03b" }}>Mobile: </span>
                    <span style={{ color: "#fff" }}>+91 9940617739 ,</span>
                  </Link>
                  <br></br>
                  <Link to="tel:+918939304633">
                    {/* <span style={{ color: "#ffb03b" }}>Mobile: </span> */}
                    <span style={{ color: "#fff" }}>+91 8939304633</span>
                  </Link>

                  <br></br>
                </p>
                <i className="bi bi-envelope"></i>
                <strong style={{ color: "#ffb03b", marginLeft: "2px" }}>
                  Email:
                </strong>
                <p>
                  <Link to="mailto:germanbakes20@gmail.com ">
                    <span style={{ color: "#fff" }}>
                      germanbakes20@gmail.com{" "}
                    </span>
                  </Link>
                </p>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="container">
                <strong style={{ color: "#ffb03b" }}> Get in Touch</strong>
              </div>
              <div className="social-links">
                <Link
                  to="https://www.facebook.com/thegermanbakes20/"
                  className="facebook"
                  target="blank"
                >
                  <i className="bx bxl-facebook"></i>
                </Link>
                <Link
                  to="https://www.instagram.com/thegermanbakes?igsh=cnBldTIzdDVyeGMy"
                  className="instagram"
                  target="blank"
                >
                  <i className="bx bxl-instagram"></i>
                </Link>
              </div>
            </div>
          </div>

          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>The German Bakes</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits">
            Designed by{" "}
            <Link to="https://brandingworld.net/">BrandingWorld</Link>
          </div>
        </div>
      </footer>
      <Link
        to="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </Link>
    </div>
  );
};
