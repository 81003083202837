import React, { useEffect, useState } from "react";
import { InnerHeader } from "../navbar/InnerHeader";
import { fetchProductList, fetchCategoryList } from "../../ApiService";
import { Link, useParams } from "react-router-dom";
import "../../App.css"; // Ensure this path is correct relative to the location of App.js
import Spinner from "./Spinner";

export const Products = () => {
  const { categoryId } = useParams();
  //  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  // const [category, setCategory] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    document.title = "Products  | The German Bakes";
    document
      .querySelector("meta[name='description']")
      .setAttribute(
        "content",
        "Our Products at The Germanbakes revolves around the world"
      );
  }, []);

  useEffect(() => {
    const loadProducts = async () => {
      try {
        const productList = await fetchProductList();
        setProducts(productList);
      } catch (error) {
        setError(error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    loadProducts();
  }, []);
  if (loading) {
    return <Spinner />; // Show the spinner while loading
  }
  return (
    <>
      <InnerHeader />
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Products</h2>
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Products</li>
            </ol>
          </div>
        </div>
      </section>
      <div className="container breads-header">
        <div className="breads-header-right">
          <h1 style={{ fontFamily: "segoe UI", fontStyle: "italic" }}>
            Our Products at The Germanbakes revolves around the world
          </h1>
          <p>
            Our story at German Bakes revolves around the principles of
            providing quality products, exceptional service, and a warm
            welcoming atmosphere to all of our customers. We take pride in our
            commitment to maintaining the highest standards of food hygiene, and
            use modern machinery and innovative baking techniques to produce the
            freshest and most delicious snacks. Our R&D team is always striving
            to create new and exciting recipes for our clients to enjoy. We also
            believe that every customer is a valued member of our family and we
            always aim to provide service with a smile. Our ultimate goal is to
            establish ourselves as the most reputable food manufacturer in India
            and to launch a global food chain in the near future.
          </p>
        </div>
      </div>
      <div class="breads-card-main">
        <h1 style={{ fontFamily: "segoe UI", fontStyle: "italic" }}>
          Our<span style={{ color: "#ffb03b" }}> Products</span>
        </h1>
        <div class="breads-card-container">
          {products.map((product) => (
            <div class="breads-card" key={product.id}>
              <img src={product.image} alt={product.name} />
              {/* <img src="assets/img/chefs-bg.jpg"></img> */}
              <div class="breads-card-content">
                <h2
                  style={{
                    color: "#ffb03b",
                    fontFamily: "segoe UI",
                    fontStyle: "italic",
                  }}
                >
                  {product.name}
                </h2>
                {/* <p>{product.description}</p> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Products;
