import React, { useEffect, useState } from "react";
import { InnerHeader } from "../navbar/InnerHeader";
import { CreateContact } from "../../ApiService";
import { Link } from "react-router-dom";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    subject: "",
    message: "",
  });

  useEffect(() => {
    document.title = "Contact us  | The German Bakes";
    document
      .querySelector("meta[name='description']")
      .setAttribute(
        "content",
        "Contact us Feel free to contact us with any questions or concerns."
      );
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await CreateContact(formData);
      console.log("Form submitted successfully:", response);
      alert("Form submitted successfully!");

      // Clear the form
      setFormData({
        name: "",
        mobile: "",
        email: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      console.error("There was an error submitting the form:", error);
      alert("There was an error submitting the form.");
    }
  };

  return (
    <div>
      <InnerHeader />
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Contact Us</h2>
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Contact Us</li>
            </ol>
          </div>
        </div>
      </section>
      <section id="contact" className="contact">
        <div className="container">
          <div className="section-title">
            <h2>
              <span>Contact</span> Us
            </h2>
            <p>
              Contact us Feel free to contact us with any questions or concerns.
              You can use the form on our website or email us directly. We
              appreciate your interest and look forward to hearing from you.
            </p>
          </div>
        </div>

        <div className="container mt-5">
          <div className="info-wrap">
            <div className="row">
              <div className="col-lg-3 col-md-6 info">
                <i className="bi bi-geo-alt"></i>
                <h4>Location:</h4>
                <p>
                  Unit of Dinetech foods pvt Ltd, 57-58, Rajas Garden, Vanagaram
                  Main Road, Vanagaram, Porur, Chennai, Tamil Nadu-600116
                </p>
              </div>

              <div className="col-lg-3 col-md-6 info mt-4 mt-lg-0">
                <i className="bi bi-clock"></i>
                <h4>Open Hours:</h4>
                <p>
                  Monday-Saturday:
                  <br />
                  08:00 AM - 10.00 PM
                </p>
              </div>

              <div className="col-lg-3 col-md-6 info mt-4 mt-lg-0">
                <i className="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>
                  <Link to="mailto:germanbakes20@gmail.com ">
                    <span style={{ color: "#7a7368" }}>
                      germanbakes20@gmail.com{" "}
                    </span>
                  </Link>
                </p>
              </div>

              <div className="col-lg-3 col-md-6 info mt-4 mt-lg-0">
                <i className="bi bi-phone"></i>
                <h4>Call:</h4>
                <p>
                  <Link to="tel:+919940617739">
                    <span style={{ color: "#7a7368" }}>+91 9940617739</span>
                  </Link>
                  <br></br>
                  <Link to="tel:+918939304633">
                    <span style={{ color: "#7a7368" }}>+91 8939304633</span>
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <form
            action="forms/contact.php"
            class="php-email-form"
            onSubmit={handleSubmit}
          >
            <div className="row">
              <div className="col-md-6 form-group">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  id="name"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6 form-group mt-3 mt-md-0">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  id="email"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-group mt-3">
              <input
                type="text"
                className="form-control"
                name="subject"
                id="subject"
                placeholder="Subject"
                value={formData.subject}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group mt-3">
              <input
                type="text"
                className="form-control"
                name="mobile"
                id="mobile"
                placeholder="Contact Number"
                value={formData.mobile}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group mt-3">
              <textarea
                className="form-control"
                name="message"
                rows="5"
                placeholder="Message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <div className="my-3">
              <div className="loading">Loading</div>
              <div className="error-message"></div>
              <div className="sent-message">
                Your message has been sent. Thank you!
              </div>
            </div>
            <div className="text-center">
              <button type="submit">Send Message</button>
            </div>
          </form>

          <div className="map">
            <iframe
              style={{
                border: "0",
                width: "100%",
                height: "350px",
                marginTop: "30px",
              }}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.8855870618822!2d80.14569107484272!3d13.042953987279008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52670e1b77d909%3A0x2d782e2a8f222072!2sThe%20German%20Bakes!5e0!3m2!1sen!2sin!4v1719475033682!5m2!1sen!2sin"
              width="600"
              height="450"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
