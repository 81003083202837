import React, { useEffect } from "react";
import { InnerHeader } from "../navbar/InnerHeader";
import { Link } from "react-router-dom";

const About = () => {
  useEffect(() => {
    document.title = "About  | The German Bakes";
    document
      .querySelector("meta[name='description']")
      .setAttribute(
        "content",
        "From Humble Beginnings to Heavenly Baked Goods!"
      );
  }, []);
  return (
    <div>
      <InnerHeader />
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>About Us</h2>
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>About Us</li>
            </ol>
          </div>
        </div>
      </section>
      <section id="about" className="about">
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-lg-5 "

              // style={{
              //   backgroundImage: "url(assets/img/oldsite/about.png)",
              // }}
            >
              <img
                src="assets/img/new/aboutus.avif"
                style={{ height: "600px", width: "100%", objectFit: "contain" }}
              ></img>
            </div>
            <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch">
              <div className="content">
                <h3>From Humble Beginnings to Heavenly Baked Goods!</h3>
                <p className="fst-italic">
                  It all started with a team that had a shared love for baking.
                  They would often come together to experiment and create new
                  recipes, sharing their delicious treats with anyone who
                  crossed their path. One day, they realized that their passion
                  for baking was more than just a hobby – it was their calling.
                </p>
                <p className="fst-italic">
                  Determined to share their love for baking with the world, they
                  decided to turn their passion into a business. And so,the
                  German Bakes was born. From the very beginning, our team was
                  committed to creating the best possible baked goods for our
                  customers.
                </p>
                <p className="fst-italic">
                  We believe that baking is more than just a science – it’s an
                  art. Each and every product we create is a masterpiece,
                  crafted with the utmost care and attention to detail. We take
                  pride in using only the finest ingredients, sourced from local
                  and sustainable suppliers whenever possible.
                </p>
                <p className="fst-italic">
                  For us, baking is about more than just creating delicious
                  treats – it’s about creating memories. We believe that our
                  products have the power to bring people together, to create
                  moments of joy and happiness that will last a lifetime.
                  Whether it’s a cozy family gathering or a grand
                  celebration,The German Bakes has the perfect baked goods to
                  make any occasion special.
                </p>
              </div>
            </div>
            <hr className="mt-5"></hr>
            <div className="container row mt-3">
              <div className="col-lg-6 mb-3">
                <strong style={{ fontSize: "25px" }}>Our Mission</strong>
                <h3>Baking Happiness Into Every Bite!</h3>
                <p>
                  Our mission is to establish ourselves as the most reputable
                  and trustworthy food manufacturer in India, creating
                  delectable and inventive food products of the utmost quality,
                  consistent with the highest standards of excellence.
                </p>
              </div>
              <div className="col-lg-6 mb-3">
                <strong style={{ fontSize: "25px" }}>Our vision</strong>
                <h3>
                  To be the Ultimate Destination for Delicious Bakery Products.
                </h3>
                <p>
                  With our focus on quality and innovation, we aim to become the
                  go-to brand for all your bakery needs. We are determined to
                  provide our customers with the most flavoursome and
                  high-quality baked goods, while also catering to their dietary
                  needs. Our ultimate goal is to expand our offerings worldwide
                  and launch a global food chain.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default About;
