import React, { useState, useEffect } from "react";
import { Header } from "../navbar/Header";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export const Home = () => {
  const [activeIndex, setActiveIndex] = useState(0); // State to manage active index

  useEffect(() => {
    document.title = "Home | The German Bakes";
    document
      .querySelector("meta[name='description']")
      .setAttribute(
        "content",
        "INDULGE IN HEAVENLY DELIGHTS, CRAFTED WITH LOVE AND BAKED TO PERFECTION, ONLY AT THE GERMAN BAKES"
      );
  }, []);

  const handleSlideTo = (index) => {
    setActiveIndex(index); // Function to update active index
  };

  // Your carousel items (assuming they are static for this example)
  const carouselItems = [
    {
      backgroundUrl: "assets/img/Banner2.jpg",
      title: "The perfect cake every time",
      content:
        "From our bakery to your store,High-quality, delicious creations, Wholesale prices, artisan touch, Perfect for your business needs.",
    },

    {
      backgroundUrl: "assets/img/Banner3.jpg",
      title: "The perfect cake every time",
      content:
        "From our bakery to your store,High-quality, delicious creations, Wholesale prices, artisan touch, Perfect for your business needs.",
    },
    // {
    //   backgroundUrl: "assets/img/slide/slide-1.jpg",
    //   title: "Artisan Breads and Pastries",
    //   content:
    //     "INDULGE IN HEAVENLY DELIGHTS, CRAFTED WITH LOVE AND BAKED TO PERFECTION, ONLY AT GERMAN BAKES.",
    //   Paragraph:
    //     "ParagraphCrusty munchies and pillowy buns, German Bakes' breads are everyone's fun.",
    // },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true, // Enables autoplay
    autoplaySpeed: 2000, // Autoplay speed in milliseconds
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const images = [
    "slider1.jpeg",
    "slider2.jpeg",
    "slider9.jpg",
    "slider4.jpeg",
    "slider10.jpg",
    "slider6.jpeg",
    "slider7.jpeg",
    "slider8.jpeg",
  ];

  return (
    <div>
      <Header />
      <section id="hero">
        <div className="hero-container w-home">
          <div
            id="heroCarousel"
            className="carousel slide carousel-fade"
            data-bs-interval="5000"
            data-bs-ride="carousel"
          >
            <ol className="carousel-indicators" id="hero-carousel-indicators">
              {carouselItems.map((item, index) => (
                <li
                  key={index}
                  className={index === activeIndex ? "active" : ""}
                  onClick={() => handleSlideTo(index)}
                  data-bs-target="#heroCarousel"
                  data-bs-slide-to={index}
                ></li>
              ))}
            </ol>
            <div className="carousel-inner" role="listbox">
              {carouselItems.map((item, index) => (
                <div
                  key={index}
                  className={`carousel-item ${
                    index === activeIndex ? "active" : ""
                  }`}
                  style={{ backgroundImage: `url(${item.backgroundUrl})` }}
                >
                  <div className="carousel-container">
                    <div className="carousel-content">
                      <h2
                        className="animate__animated animate__fadeInDown"
                        style={{ fontFamily: "segoe UI", fontStyle: "italic" }}
                      >
                        {item.title}
                      </h2>
                      <p className="animate__animated animate__fadeInUp">
                        {item.content}
                      </p>
                      <p className="animate__animated animate__fadeInUp">
                        {item.Paragraph}
                      </p>
                      <div>
                        <Link
                          to="/contact"
                          className="btn-menu animate__animated animate__fadeInUp scrollto"
                        >
                          Contact us
                        </Link>
                        {/* <a
                          href="#book-a-table"
                          className="btn-book animate__animated animate__fadeInUp scrollto"
                        >
                          Book a Table
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <a
              className="carousel-control-prev"
              href="#heroCarousel"
              role="button"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon bi bi-chevron-left"
                aria-hidden="true"
              ></span>
            </a>
            <a
              className="carousel-control-next"
              href="#heroCarousel"
              role="button"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon bi bi-chevron-right"
                aria-hidden="true"
              ></span>
            </a>
          </div>
        </div>
      </section>

      {/*------------------------- round -------------------------image         */}
      <div className=" product-images-main w-home" style={{ width: "100%" }}>
        <div
          className="product-images-description  col-lg-12  pb-4"
          style={{ width: "100%" }}
        >
          <h1 className="Breadandpastries">
            Bread and pastries are the foundations of comfort food.
          </h1>
          <h3>
            Ensuring our customers enjoy a product that's always freshly baked
            and straight from the oven.<br></br>
            Our commitment is to deliver warm, delightful treats that bring joy
            with every bite.
          </h3>
        </div>
      </div>

      <div className="home-breads-card mt-3  pt-3" style={{ width: "100%" }}>
        <div class="breads-card">
          <img src="assets/img/new/dry-cake.JPG" alt="images" />
          {/* <img src="assets/img/chefs-bg.jpg"></img> */}
          <div class="home-breads-card-content">
            <h2
              style={{
                color: "#ffb03b",
                fontFamily: "segoe UI",
                fontStyle: "italic",
                fontSize: "26px",
              }}
            >
              Dry Cake
            </h2>
            <p>
              {" "}
              Banana Cake, Walnut Cake, Carrot Cake, Condensed Milk, Butter
              Scotch Cake
            </p>
          </div>
        </div>
        <div class="breads-card">
          <img src="assets/img/reference/cup-cake.jpeg" alt="images" />
          {/* <img src="assets/img/chefs-bg.jpg"></img> */}
          <div class="home-breads-card-content">
            <h2
              style={{
                color: "#ffb03b",
                fontFamily: "segoe UI",
                fontStyle: "italic",
                fontSize: "26px",
              }}
            >
              Cup Cake
            </h2>
            <p>
              {" "}
              Red Velvet with Cheese Frosting, Vanilla With Classic Frosting,
              Banana With Chocolate Frosting, Carrot Cup Cake With Cheese
              Frosting, Hazelnut Cup Cake With Nutella Frosting.
            </p>
          </div>
        </div>
        <div class="breads-card">
          <img src="assets/img/reference/Dougnats.jpeg" alt="images" />
          {/* <img src="assets/img/chefs-bg.jpg"></img> */}
          <div class="home-breads-card-content">
            <h2
              style={{
                color: "#ffb03b",
                fontFamily: "segoe UI",
                fontStyle: "italic",
                fontSize: "26px",
              }}
            >
              Doughnuts
            </h2>
            <p>
              {" "}
              Milk Chocolate, Creme Caramel, Salted Caramel, Irish Coffee,
              Filter Coffee, Dark Chocolate, White Chocolate, Swiss Mango, Swiss
              Rasberry, Pistachio, Oreo Cheese Cake
            </p>
          </div>
        </div>
      </div>

      {/*-------------------- middle Banner---------------------------- */}
      <div className="banner2 mt-4" style={{ width: "100%" }}>
        {/* <img src="assets/img/Banner2.avif" alt="Banner Image" /> */}
        <div className="text">
          INDULGE IN HEAVENLY DELIGHTS, CRAFTED WITH LOVE AND BAKED TO
          PERFECTION, ONLY AT THE GERMAN BAKES
        </div>
      </div>

      <section id="specials" className="specials">
        <div className="container">
          <div className="section-title">
            <h2 style={{ fontFamily: "segoe UI", fontStyle: "italic" }}>
              Our Palate Pleasures: <span>A Taste of Perfection</span>
            </h2>
            <p>
              We produce superior patisserie, confectionery, and a variety of
              cookies and desserts both live and frozen. We are a top producer
              of frozen doughnuts, cake sponges, and puff sheets.
            </p>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <ul className="nav nav-tabs flex-column">
                <li className="nav-item">
                  <a
                    className="nav-link active show"
                    data-bs-toggle="tab"
                    href="#tab-1"
                  >
                    French Viennoiserie
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" href="#tab-2">
                    Breads & Rolls
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" href="#tab-3">
                    Savouries
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" href="#tab-4">
                    Eclairs
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" href="#tab-5">
                    Celebration Cakes
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" href="#tab-6">
                    Doughnuts
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" href="#tab-7">
                    Cup Cake
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" href="#tab-8">
                    Dry Cake
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-9 mt-4 mt-lg-0">
              <div className="tab-content">
                <div className="tab-pane active show" id="tab-1">
                  <div className="row ">
                    <div className="col-lg-8 details order-2 order-lg-1">
                      <p className="fst-italic">
                        Butter Croissant, Almond Croissant, Mango Croissant,
                        Pistachio Croissant, Cinna Buns, Cheese Danish, Fresh
                        Fruit Danish, Praline Danish, Nutella Danish, Apple
                        Cinnamon Danish, Almond Danish, Bluberry Danish,
                        Raspberry Danish
                      </p>
                    </div>
                    <div className="col-lg-4 text-center order-1 order-lg-2">
                      <img
                        src="assets/img/reference/french1.jpeg"
                        alt=""
                        className="img-fluid"
                        // style={{ height: "250px ", width: "200px" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="tab-2">
                  <div className="row">
                    <div className="col-lg-8 details order-2 order-lg-1">
                      <p className="fst-italic">
                        French Baguette, Sour Dough, Multi Grain, Wheat Bread,
                        Whole Wheat Bread, Olive And Jalepeno Breads, Olive And
                        Onion Foccacia, Tomato Foccacia, Maska Pav
                      </p>
                    </div>
                    <div className="col-lg-4 text-center order-1 order-lg-2">
                      <img
                        src="assets/img/new/Breadroll.JPG"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="tab-3">
                  <div className="row">
                    <div className="col-lg-8 details order-2 order-lg-1">
                      <p className="fst-italic">
                        Malai Paneer Puff, Seasonal Vegetable Puff, Chicken
                        Puff, Podimas Puff, Mushroom Puff, Chicken Bun, Paneer
                        Bun, Buns ( Onion Spicy Mayo, Garlic Mayo, Cheese
                        Tomato)
                      </p>
                    </div>
                    <div className="col-lg-4 text-center order-1 order-lg-2">
                      <img
                        src="assets/img/new/savouries.JPG"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="tab-4">
                  <div className="row">
                    <div className="col-lg-8 details order-2 order-lg-1">
                      <p className="fst-italic">
                        White, Milk, Dark, Rasberry, Mango Pistachio
                      </p>
                    </div>
                    <div className="col-lg-4 text-center order-1 order-lg-2">
                      <img
                        src="assets/img/reference/eclairs.jpeg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="tab-5">
                  <div className="row">
                    <div className="col-lg-8 details order-2 order-lg-1">
                      <p className="fst-italic">
                        Lava Cake, Fresh Mango, Fresh Pineapple, Strawberry,
                        Black Current, Blueberry White Chocolate, Choco Truffle,
                        White Truffle, Naked, Mirror Glaze
                      </p>
                    </div>
                    <div className="col-lg-4 text-center order-1 order-lg-2">
                      <img
                        src="assets/img/oldsite/rosecake.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="tab-6">
                  <div className="row">
                    <div className="col-lg-8 details order-2 order-lg-1">
                      <p className="fst-italic">
                        Milk Chocolate, Creme Caramel, Salted Caramel, Irish
                        Coffee, Filter Coffee, Dark Chocolate, White Chocolate,
                        Swiss Mango, Swiss Rasberry, Pistachio, Oreo Cheese Cake
                      </p>
                    </div>
                    <div className="col-lg-4 text-center order-1 order-lg-2">
                      <img
                        src="assets/img/new/dounats.JPG"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="tab-7">
                  <div className="row">
                    <div className="col-lg-8 details order-2 order-lg-1">
                      <p className="fst-italic">
                        Red Velvet with Cheese Frosting, Vanilla With Classic
                        Frosting, Banana With Chocolate Frosting, Carrot Cup
                        Cake With Cheese Frosting, Hazelnut Cup Cake With
                        Nutella Frosting
                      </p>
                    </div>
                    <div className="col-lg-4 text-center order-1 order-lg-2">
                      <img
                        src="assets/img/reference/cup-cake1.jpeg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="tab-8">
                  <div className="row">
                    <div className="col-lg-8 details order-2 order-lg-1">
                      <p className="fst-italic">
                        Banana Cake, Walnut Cake, Carrot Cake, Condensed Milk,
                        Butter Scotch Cake
                      </p>
                    </div>
                    <div className="col-lg-4 text-center order-1 order-lg-2">
                      <img
                        src="assets/img/new/dry-cake.JPG"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="gallery"
        className="gallery pb-4"

        // style={{ width: "100%", boxSizing: "border-box", padding: "0 2.5%" }}
      >
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="gallery-item">
              <Link
                href={`assets/img/gallery/gallery-${index + 1}.jpg`}
                className="gallery-lightbox"
              >
                <img
                  src={`assets/img/slider/${image}`}
                  alt=""
                  className="img-fluid"
                  style={{
                    width: "100%",
                    height: "283px",
                    borderRadius: "10px",
                  }}
                />
              </Link>
            </div>
          ))}
        </Slider>
      </section>
    </div>
  );
};
