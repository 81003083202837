import axios from "axios";

const BASE_URL = "https://germanbakes.bwsoft.in/api/";
const ApiService = axios.create({
  baseURL: BASE_URL,
});

export const fetchCategoryList = async () => {
  try {
    const response = await ApiService.get("category-list/");
    return response.data;
  } catch (error) {
    console.error("Error during Category fetch", error);
    throw error;
  }
};

export const fetchProductList = async () => {
  try {
    const response = await ApiService.get(`products/`);
    return response.data;
  } catch (error) {
    console.error("Error during Product fetch", error);
    throw error;
  }
};

export const CreateContact = async (contactData) => {
  try {
    const response = await axios.post(`${BASE_URL}contact/`, contactData);
    return response.data;
  } catch (error) {
    console.error("Error during Filling Form", error);
    throw error;
  }
};
