import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { fetchCategoryList } from "../../ApiService";

export const InnerHeader = () => {
  const headerRef = useRef(null);
  const topbarRef = useRef(null);
  const navbarLinksRef = useRef([]);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const select = (el, all = false) => {
    el = el.trim();
    if (all) {
      return [...document.querySelectorAll(el)];
    } else {
      return document.querySelector(el);
    }
  };

  const scrollto = (el) => {
    const header = select("#header");
    const offset = header.offsetHeight;
    const elementPos = select(el).offsetTop;
    window.scrollTo({
      top: elementPos - offset,
      behavior: "smooth",
    });
  };

  const navbarlinksActive = () => {
    let position = window.scrollY + 200;
    navbarLinksRef.current.forEach((navbarlink) => {
      const { hash } = navbarlink;
      if (!hash || !hash.startsWith("#") || hash === "#" || hash === "#/")
        return;

      // Strip the leading "#/" for valid CSS selector
      const sectionId = hash.replace(/^#\/?/, "#");
      const section = select(sectionId);
      if (!section) return;

      if (
        position >= section.offsetTop &&
        position <= section.offsetTop + section.offsetHeight
      ) {
        navbarlink.classList.add("active");
      } else {
        navbarlink.classList.remove("active");
      }
    });
  };

  const headerScrolled = () => {
    if (window.scrollY > 100) {
      headerRef.current.classList.add("header-scrolled");
      if (topbarRef.current) {
        topbarRef.current.classList.add("topbar-scrolled");
      }
    } else {
      headerRef.current.classList.remove("header-scrolled");
      if (topbarRef.current) {
        topbarRef.current.classList.remove("topbar-scrolled");
      }
    }
  };

  useEffect(() => {
    navbarLinksRef.current = select("#navbar .scrollto", true);

    window.addEventListener("load", navbarlinksActive);
    window.addEventListener("scroll", navbarlinksActive);
    window.addEventListener("load", headerScrolled);
    window.addEventListener("scroll", headerScrolled);

    return () => {
      window.removeEventListener("load", navbarlinksActive);
      window.removeEventListener("scroll", navbarlinksActive);
      window.removeEventListener("load", headerScrolled);
      window.removeEventListener("scroll", headerScrolled);
    };
  }, []);

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const categories = await fetchCategoryList();
        setCategory(categories);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    loadCategories();
  }, []);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div>
      <section id="topbar" className="d-flex align-items-center fixed-top">
        <div className="container-fluid container-xl d-flex align-items-center justify-content-center justify-content-lg-start">
          <i className="bi bi-phone d-flex align-items-center">
            <Link to="tel:+919940617739">
              <span>+91 9940617739</span>
            </Link>
          </i>
          <i className="bi bi-clock ms-4 d-none d-lg-flex align-items-center">
            <span>Mon-Sat: 08:00 AM - 10:00 PM</span>
          </i>
        </div>
      </section>
      <header
        id="header-1"
        className="fixed-top d-flex align-items-center"
        ref={headerRef}
      >
        <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
          <div className="logo me-auto">
            <Link to="/">
              <img src="assets/img/logo.png" alt="logo" id="header" />
            </Link>
          </div>

          <nav
            id="navbar"
            className={`navbar order-last order-lg-0 ${
              isNavOpen ? "navbar-mobile" : ""
            }`}
          >
            <ul>
              <li>
                <Link className="nav-link scrollto active" to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link className="nav-link scrollto  " to="/products">
                  Products
                </Link>
              </li>

              <li>
                <Link className="nav-link scrollto" to="/about">
                  About
                </Link>
              </li>

              <li>
                <Link className="nav-link scrollto" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle" onClick={toggleNav}></i>
          </nav>
        </div>
      </header>
    </div>
  );
};
